<template>
    <div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <splide :options="slide">
                    <splide-slide class="text-center h-auto">
                        <a href="javascript:void(0)" @click="showTab(1)">
                            <div style="background-image: url('/images/started.png'); background-position: center; background-size: auto 80%; background-repeat: no-repeat;" class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                <div class="card-body py-4">
                                    <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">File Url Generator</h1>    
                                </div>
                            </div>
                        </a>
                    </splide-slide>
                </splide>
            </div>
        </div>
        <div class="row mb-3" v-if="isShow">
            <div class="col-12">
                <div class="row" v-if="tab == 1">
                    <div class="col-lg-12">
                        <File/>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import File from './Sub/File/File.vue'
export default {
    components:{
        File
    },
    name: "Settings",
    data(){
        return {
            user: {},
            isShow: false,
            slide: {
            rewind : false,
            perPage : 2,
            gap : '2rem',
            autoplay : false,
            arrows : true,
            pagination : false,
            breakpoints: {
                '800' : {
                    perPage: 2,
                    gap: '10rem'
                },
                '640' : {
                    perPage: 1,
                    gap: '4rem'
                },
                '480' : {
                    perPage: 1,
                    gap: '2rem'
                },
            }
        },
        }
    },
    methods:{
        showTab(tab) {
            this.isShow = false
            this.tab = tab
            this.isShow = true
        },
    }
}
</script>
