<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-around">
            <div class="card-body mb-3 pb-0 col-12">
                <router-link :to="{name: 'HelpFileRepositoryCreateAdmin'}" class="btn btn-current float-right"><i class="fas fa-plus mr-2"></i>Add new</router-link>
                <h2 class="fw-400 font-lg d-block"><b>File Repository</b></h2>
            </div>
            <div class="col-12">
                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
            </div>
            <div class="card-body p-0">
                <div v-if="!isLoad" class="col-12 text-center py-3">
                    <div class="table-responsive mw-100 px-1">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ row.filename }}</td>
                                    <td>
                                        <input type="text" :value=" row.path" class="form-control" :id="`textToCopy-${index}`">
                                    </td>
                                    <td>{{ row.user ? row.user.name : '' }}</td>
                                    <td>
                                        <a href="javascript:void(0)" @click="copyUrl(index)" class="btn btn-sm btn-current mr-2">
                                            <i class="fas fa-copy"></i> 
                                        </a>
                                        <a :disabled="row.path == null" :href=" row.path" target="_blank" class="btn btn-sm btn-success mr-2"><i class="fas fa-file"></i></a>
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></a>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Everyone',
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Filename', field: 'filename', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Url', field: 'path', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Created By', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Action', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                
            ]
        }
    },
    created(){
        this.getFile()
    },
    methods:{
        async getFile(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/file-repository`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
        copyUrl(index){
            if (this.path != ''){
                let textToCopy = this.$el.querySelector('input#textToCopy-' + index)
                textToCopy.select()
                document.execCommand("copy");
                this.$swal({
                    toast: true,
                    title: 'File Url!',
                    text: 'Copied!',
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/file-repository`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'File Repository!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getFile()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'File Repository!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
}
</script>